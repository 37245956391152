<template>
  <div>
    <el-table
    v-loading='loading'
    :data="banners ? banners.filter(data => !search || data.title.toLowerCase().includes(search.toLowerCase())) : null"
    :show-header="true"
    style="width: 100%"
    @cell-click="clickCell">
        <el-table-column label="Зураг">
         <template slot-scope="scope">
          <el-row :gutter="10" align="left" type="flex">
            <el-col :span="12">
              <div class="image-holder">
                <img :src="scope.row.image_url">
              </div>
            </el-col>
          </el-row>
        </template>
      </el-table-column>
      <el-table-column label="Гарчиг">
         <template slot-scope="scope">
           <div>{{scope.row.title}}</div>
        </template>
      </el-table-column>
      <!-- <el-table-column label="Тайлбар">
        <template slot-scope="scope">
          <div>{{scope.row.description}}</div>
        </template>
      </el-table-column> -->
      <el-table-column label="Төлөв">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.status === 1" type="success" effect="dark">Active</el-tag>
          <el-tag v-else-if="scope.row.status === 0" type="info" effect="dark">Expired</el-tag>
          <el-tag v-else type="warning" effect="dark">{{scope.row.status}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="Эхлэх огноо">
        <template slot-scope="scope">
          <div>{{formatDate(scope.row.start_date)}}</div>
        </template>
        </el-table-column>
        <el-table-column label="Дуусах огноо">
         <template slot-scope="scope">
          <div>{{formatDate(scope.row.end_date)}}</div>
         </template>
        </el-table-column>
        <el-table-column>
          <template slot-scope="scope">
            <router-link :to="{ name: 'bannerEdit', params: { id: scope.row.id }}">
              <el-button type="primary" icon="el-icon-edit" circle></el-button>
            </router-link>
          </template>
        </el-table-column>
    </el-table>
    <el-pagination
      @current-change = "curentPageHandler"
      @size-change = "sizeChangeHandler"
      class="text-right mt10"
      background
      :current-page.sync="currentPage"
      :page-size="pageSize"
      :page-sizes="[5, 20, 50, 100]"
      layout="total, sizes, prev, pager, next"
      :total="totalCount"
    >
    </el-pagination>
  </div>
</template>

<script>
import helper from '../../../../helpers/helper'
export default {
  name: 'bannerTable',
  props: [
    'totalCount',
    'currentPage',
    'curentPageHandler',
    'sizeChangeHandler',
    'banners',
    'pageSize',
    'loading',
    'search'
  ],

  methods: {
    clickCell (row, column, cell, event) {
      this.$router.push(
        {
          name: 'bannerDetail',
          params: {
            id: row.id
          }
        }
      )
    },

    formatDate (val) {
      return helper.formatDate(val, 'YYYY/MM/DD')
    }
  }
}
</script>

<style>

</style>
